import api from '@/services/api';

const buildHeaders = resetToken => {
  return {
    headers: {
      'Reset-Token': resetToken,
      DOMAIN: 'CONTAAZUL',
    },
  };
};

export const requestPasswordUpdate = ({ resetToken, password }) => {
  return api.post('/user/password-update', { password }, buildHeaders(resetToken));
};

export const requestResetTokenValidate = ({ resetToken }) => {
  return api.post('/user/password-update/reset-token-validation', {}, buildHeaders(resetToken));
};

export const accountTransferConfirmation = ({ resetToken, password }) => {
  const params = {
    token: resetToken,
    value: password,
  };
  return api.post('/user-invite/confirmation', params);
};

export const accountTransferTokenValidate = ({ resetToken }) => {
  return api.post('/user-invite/reset-token-validation', {}, buildHeaders(resetToken));
};
