<template>
  <div class="reset-password-page">
    <div class="reset-password-page__row">
      <div class="reset-password-page__column reset-password-page__column__content-side">
        <div class="reset-password-page__content-side">
          <content-side />
        </div>
      </div>
      <div class="reset-password-page__column reset-password-page__column__blank-side">
        <div class="reset-password-page__blank-side"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentSide from '@/components/contentSide/ContentSide';

export default {
  name: 'PasswordRecoveryPage',
  components: {
    ContentSide,
  },
};
</script>

<style scoped>
.reset-password-page__row {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: start;
  align-items: flex-start;
  margin: 0;
  height: 100vh;
}

.reset-password-page__column {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  padding: 0;
  position: relative;
}

.reset-password-page__column__content-side {
  background-color: #ffffff;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  min-height: 100vh;
}

.reset-password-page__content-side {
  margin: auto;
  max-width: 450px;
  padding: 5em 4em 1em 4em;
}

.reset-password-page__column__blank-side {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.reset-password-page__blank-side {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #2687e9;
}
.blank-side {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #2687e9;
}

@media screen and (max-width: 1199px) {
  .reset-password-page__column__content-side {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .reset-password-page__content-side {
    padding: 4em 2em 1em 2em;
  }

  .reset-password-page__column__blank-side {
    display: none;
  }

  .blank-side {
    display: none;
  }
}
</style>
