<template>
  <div class="ds-u-margin-top">
    <ds-row>
      <ds-col v-if="alert">
        <ds-box-information :theme="alert.theme" :title="alert.title" :message="alert.message"></ds-box-information>
      </ds-col>
    </ds-row>
    <ds-row>
      <ds-col>
        <ds-p class="ds-u-font--semibold">Não recebeu o e-mail?</ds-p>
        <ds-p>
          Confira a sua caixa de Spam. Se também não estiver lá, solicite o link novamente,
          <ds-link
            ref="linkContactOurSupport"
            href="mailto:suporte@contaazul.com"
            @click="trackOnClick('clickedLinkContactOurSupport')">
            ou entre em contato com o nosso Suporte
          </ds-link>
        </ds-p>
      </ds-col>
    </ds-row>
    <ds-row>
      <ds-col size="6">
        <ds-button full-width @click="backToLoginPage"> Voltar para o login </ds-button>
      </ds-col>
      <ds-col size="6">
        <ds-request-button
          theme="primary"
          :on-request="resendEmail"
          :on-request-success="onResendEmailSuccess"
          :on-request-error="onResendEmailError">
          Reenviar Link
        </ds-request-button>
      </ds-col>
    </ds-row>
  </div>
</template>

<script>
import getRequestErrorMessage from '@/services/requestErrorMessage';
import track from '@/services/trackService';
import TRACKS_EVENTS from '@/services/trackConstants';
import { requestPasswordRecovery } from '@/resources/passwordRecovery';
import {
  triedToResendEmailTrack,
  successResendEmailTrack,
  errorResendEmailTrack,
} from '@/services/authenticationTrack/authenticationTrackService';
import { REQUEST_RECOVER_PASSWORD_ERROR } from './ForgotPasswordRequestMessages';
import ForgotPasswordStore from './ForgotPasswordStore';

export default {
  name: 'ForgotPasswordSuccess',
  data() {
    return {
      alert: this.getSuccessMessage(),
    };
  },
  methods: {
    resendEmail() {
      track({
        ...TRACKS_EVENTS.clickedLinkToResendEmailWithResetToken,
        properties: {
          email: ForgotPasswordStore.email,
        },
      });
      this.alert = null;
      triedToResendEmailTrack({ email: ForgotPasswordStore.email });
      return requestPasswordRecovery({
        email: ForgotPasswordStore.email,
      });
    },
    onResendEmailSuccess() {
      successResendEmailTrack({ email: ForgotPasswordStore.email });
      this.alert = this.getSuccessMessage();
    },
    onResendEmailError(error) {
      const { title, description } = getRequestErrorMessage(error, REQUEST_RECOVER_PASSWORD_ERROR.GENERIC_FAIL);
      errorResendEmailTrack({
        email: ForgotPasswordStore.email,
        properties: {
          error,
        },
      });

      this.alert = {
        theme: 'danger',
        title,
        message: description,
      };
    },
    getSuccessMessage() {
      return {
        theme: 'success',
        title: '',
        message: `Enviamos um link para você recuperar a sua senha no e-mail: ${ForgotPasswordStore.email}`,
      };
    },
    backToLoginPage() {
      track(TRACKS_EVENTS.clickedButtonGoBackLoginPage);
      this.$router.push('/');
    },
    trackOnClick(eventName) {
      track({
        ...TRACKS_EVENTS[eventName],
        properties: {
          email: ForgotPasswordStore.email,
        },
      });
    },
  },
};
</script>
