const routeSandbox = () => {
  if (process.env.VUE_APP_ENV !== 'production') {
    return [
      {
        path: '/sandbox',
        name: 'LoginSandboxForm',
        component: () => import('@/views/loginSandbox/LoginSandboxPage.vue'),
      },
    ];
  }
  return [];
};

export default routeSandbox();
