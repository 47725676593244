export const PRODUCT = {
  CAPRO: {
    location: 'https://app.contaazul.com',
    signin: 'https://login.contaazul.com',
    confirmSignup: 'https://login.contaazul.com/#/verificacao-duas-etapas',
  },
  CAMAIS: {
    location: 'https://contabilidade.contaazul.com',
    signin: 'https://contabilidade.contaazul.com/login/login.html',
  },
  DEFAULT: {
    location: getOriginURL(),
    signin: 'https://login.contaazul.com',
    confirmSignup: 'https://login.contaazul.com/#/verificacao-duas-etapas',
  },
};

export const PRODUCT_SANDBOX = {
  CAPRO: {
    signin: 'http://authentication-front.dev.contaazul.local/index.html',
    confirmSignup: 'http://authentication-front.dev.contaazul.local/index.html#/verificacao-duas-etapas',
  },
  CAMAIS: {
    signin: 'https://contabilidade.contaazul.com/login/login.html',
  },
  DEFAULT: {
    signin: 'http://authentication-front.dev.contaazul.local/index.html',
    confirmSignup: 'http://authentication-front.dev.contaazul.local/index.html#/verificacao-duas-etapas',
  },
};

export function getOriginURL() {
  return getURLDefault();
}

export function getURLDefault() {
  return 'https://app.contaazul.com';
}
