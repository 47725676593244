import jwtDecode from 'jwt-decode';
import { to } from 'await-to-js';
import { getError } from './errorsService';
import { getProductLocationByUser } from './locationService';
import { bootstrapAuth } from './bootstrapAuthTokenResource';
import { bootstrapJWT } from './bootstrapJWTResource';
import { hasJWTCognitoAB } from './cognitoService';

export async function bootstrapAuthToken(userData, app) {
  let auth;

  if (userData?.signInUserSession) {
    auth = {
      access_token: userData?.signInUserSession?.accessToken?.jwtToken,
      expires_in: userData?.signInUserSession?.idToken?.payload?.exp,
      id_token: userData?.signInUserSession?.idToken?.jwtToken,
      refresh_token: userData?.signInUserSession?.refreshToken?.token,
      token_type: 'Bearer',
    };
  }

  if (auth == null) {
    console.log('erro: objeto auth inválido');
    console.log(userData);

    const error = { error: JSON.stringify({ ...getError('MissingTokenData') }) };
    return [error, null];
  }

  const { userId } = jwtDecode(auth.id_token);

  if (!userId) {
    console.log('erro: usuario CAPRO indisponível');
    const error = { error: JSON.stringify({ ...getError('MissingTokenDataAfterLogin') }) };
    return [error, null];
  }

  const isJWT = await hasJWTCognitoAB(userData?.username);

  if (isJWT) {
    const jwt = { token: auth.access_token };
    const [err] = await to(bootstrapJWT(jwt));

    if (err) {
      const error = { message: getError(err.code, null), step: 'bootstrapJWT' };
      console.log(err);
      return [error, null];
    }
  } else {
    const [err] = await to(bootstrapAuth(auth));

    if (err) {
      const error = { message: getError(err.code, null), step: 'bootstrapAuth' };
      return [error, null];
    }
  }

  return { location: getProductLocationByUser({ app, userId }), jwt: isJWT };
}
