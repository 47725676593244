<template>
  <div>
    <ds-row>
      <ds-col>
        <ds-heading text="Alteração de Telefone" />
      </ds-col>
    </ds-row>
    <ds-row>
      <ds-col>
        <ds-p>
          Ao alterar seu número de telefone, uma mensagem de texto com um código de verificação de 6 dígitos será
          enviada.
        </ds-p>
      </ds-col>
    </ds-row>
    <ds-row vertical-offset="2">
      <ds-col>
        <ds-field label="Digite o novo telefone">
          <ds-phone-input v-model="newPhone" required />
        </ds-field>
        <ds-request-button
          theme="primary"
          :on-request="changePhone"
          :on-request-success="onChangePhoneSuccess"
          :on-request-error="onChangePhoneError">
          Alterar
        </ds-request-button>
        <ds-button theme="link" @click="cancel"> Cancelar </ds-button>
      </ds-col>
    </ds-row>
  </div>
</template>

<script>
import { dsToasterService } from '@contaazul/design-system';
import { configure } from '@auth-integration-lib/authentication-lib';

export default {
  name: 'MfaSignupVerification',
  data() {
    return {
      phone: '',
      email: '',
      newPhone: '',
      redirect: '',
    };
  },
  mounted() {
    const { email, phone, redirect = '' } = this.$route.params;

    if (!phone) {
      this.redirectToLoginPage();
      return;
    }

    this.phone = atob(phone);
    this.newPhone = atob(phone);
    this.email = atob(email);
    this.redirect = redirect;
  },
  methods: {
    changePhone() {
      return configure(process.env.VUE_APP_ENV).updateUser({
        email: this.email,
        flow: 'CONFIRM_USER',
        attributes: {
          phone_number: this.newPhone,
        },
      });
    },
    onChangePhoneSuccess({ data }) {
      dsToasterService.success({
        title: 'Sucesso!',
        content: data.message,
      });

      this.redirectMFASignup({ resend: true });
    },
    onChangePhoneError({ title, detail }) {
      dsToasterService.error({
        title,
        content: detail,
      });
    },
    redirectToLoginPage() {
      this.$router.push({ name: 'Login' });
    },
    cancel() {
      this.redirectMFASignup({});
    },
    redirectMFASignup({ resend = false }) {
      const query = {
        email: Buffer.from(this.email).toString(`base64`),
        phone: Buffer.from(this.newPhone).toString(`base64`),
      };

      if (this.redirect) {
        query.redirect = this.redirect;
      }

      if (resend) {
        query.resend = resend;
      }

      this.$router.push({
        name: 'MFASignup',
        query,
      });
    },
  },
};
</script>
