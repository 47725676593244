import Vue from 'vue';
import Router from 'vue-router';
import { configure } from '@auth-integration-lib/authentication-lib';
import routes from './routes';

Vue.use(Router);

const route = new Router({
  routes,
});

const CONFIMED_ROUTES_NAME = ['MFASignup', 'MfAChangePhonePage'];

const isWhiteListedRoute = routeName => {
  return CONFIMED_ROUTES_NAME.includes(routeName);
};

route.beforeEach(async (to, from, next) => {
  const { name, params, query } = to;

  if (isWhiteListedRoute(name)) {
    const email = params?.email || query?.email;

    if (!email) return next({ name: 'Login' });

    try {
      const { data = {} } = await configure(process.env.VUE_APP_ENV).verifyConfirmedUser({
        email: atob(email),
      });

      const { userConfirmed = true } = data;

      if (isWhiteListedRoute(name) && userConfirmed) return next({ name: 'Login' });
    } catch {
      return next({ name: 'Login' });
    }
  }

  return next();
});

export default route;
