import { v4 as uuidV4 } from 'uuid';
import * as authenticationTrackResource from '@/resources/authenticationTrackResource';
import {
  PAGES,
  LOGIN_ACTIONS,
  MFA_CONFIRM_SIGNIN_ACTIONS,
  MFA_TOTP_CONFIGURATION_ACTIONS,
  PASSWORD_RECOVERY_ACTIONS,
  AUTH_TYPE,
  OUTCOME,
} from './authenticationTrackConstants.js';

function sendTrack(data) {
  if (process.env.NODE_ENV === 'development') {
    console.log(data);
    return;
  }

  const trackData = {
    id: uuidV4(),
    origin: window.location.hostname,
    date: new Date().toISOString(),
    ...data,
  };

  if (trackData.properties) {
    trackData.properties = JSON.stringify(trackData.properties);
  }

  return authenticationTrackResource.sendTrack(trackData).catch(() => Promise.resolve({}));
}

export const triedToLoginTrack = ({ email, isCognito }) => {
  return sendTrack({
    email,
    page: PAGES.login,
    action: LOGIN_ACTIONS.triedToLogin,
    type: isCognito ? AUTH_TYPE.cognito : AUTH_TYPE.heimdall,
    outcome: OUTCOME.success,
  });
};

export const errorLoginTrack = ({ email, isCognito, properties }) => {
  return sendTrack({
    email,
    properties,
    page: PAGES.login,
    action: LOGIN_ACTIONS.errorLogin,
    type: isCognito ? AUTH_TYPE.cognito : AUTH_TYPE.heimdall,
    outcome: OUTCOME.error,
  });
};

export const successLoginTrack = ({ email, isCognito, properties }) => {
  return sendTrack({
    email,
    properties,
    page: PAGES.login,
    action: LOGIN_ACTIONS.successLogin,
    type: isCognito ? AUTH_TYPE.cognito : AUTH_TYPE.heimdall,
    outcome: OUTCOME.success,
  });
};

export const triedToConfirmMfaSigninTrack = ({ email, properties }) => {
  return sendTrack({
    email,
    properties,
    page: PAGES.mfaSigninVerification,
    action: MFA_CONFIRM_SIGNIN_ACTIONS.triedToConfirm,
    type: AUTH_TYPE.cognito,
    outcome: OUTCOME.success,
  });
};

export const errorConfirmMfaSigninTrack = ({ email, properties }) => {
  return sendTrack({
    email,
    properties,
    page: PAGES.mfaSigninVerification,
    action: MFA_CONFIRM_SIGNIN_ACTIONS.errorConfirm,
    type: AUTH_TYPE.cognito,
    outcome: OUTCOME.error,
  });
};

export const successConfirmMfaSigninTrack = ({ email, properties }) => {
  return sendTrack({
    email,
    properties,
    page: PAGES.mfaSigninVerification,
    action: MFA_CONFIRM_SIGNIN_ACTIONS.successConfirm,
    type: AUTH_TYPE.cognito,
    outcome: OUTCOME.success,
  });
};

export const resendCodeConfirmMfaSigninTrack = ({ email }) => {
  return sendTrack({
    email,
    page: PAGES.mfaSigninVerification,
    action: MFA_CONFIRM_SIGNIN_ACTIONS.resendCode,
    type: AUTH_TYPE.cognito,
    outcome: OUTCOME.success,
  });
};

export const triedToChangePhoneMfaSigninTrack = ({ email }) => {
  return sendTrack({
    email,
    page: PAGES.mfaSigninVerification,
    action: MFA_CONFIRM_SIGNIN_ACTIONS.triedToChangePhone,
    type: AUTH_TYPE.cognito,
    outcome: OUTCOME.success,
  });
};

export const triedToSetupTotpTrack = ({ email }) => {
  return sendTrack({
    email,
    page: PAGES.totpConfiguration,
    action: MFA_TOTP_CONFIGURATION_ACTIONS.triedToSetup,
    type: AUTH_TYPE.cognito,
    outcome: OUTCOME.success,
  });
};

export const errorSetupTotpTrack = ({ email, properties }) => {
  return sendTrack({
    email,
    properties,
    page: PAGES.totpConfiguration,
    action: MFA_TOTP_CONFIGURATION_ACTIONS.errorSetup,
    type: AUTH_TYPE.cognito,
    outcome: OUTCOME.error,
  });
};

export const successSetupTotpTrack = ({ email }) => {
  return sendTrack({
    email,
    page: PAGES.totpConfiguration,
    action: MFA_TOTP_CONFIGURATION_ACTIONS.successSetup,
    type: AUTH_TYPE.cognito,
    outcome: OUTCOME.success,
  });
};

export const triedToValidateTotpCodeTrack = ({ email }) => {
  return sendTrack({
    email,
    page: PAGES.totpConfiguration,
    action: MFA_TOTP_CONFIGURATION_ACTIONS.triedToValidateCode,
    type: AUTH_TYPE.cognito,
    outcome: OUTCOME.success,
  });
};

export const errorValidateTotpCodeTrack = ({ email, properties }) => {
  return sendTrack({
    email,
    properties,
    page: PAGES.totpConfiguration,
    action: MFA_TOTP_CONFIGURATION_ACTIONS.errorValidateCode,
    type: AUTH_TYPE.cognito,
    outcome: OUTCOME.error,
  });
};

export const successValidateTotpCodeTrack = ({ email, properties }) => {
  return sendTrack({
    email,
    properties,
    page: PAGES.totpConfiguration,
    action: MFA_TOTP_CONFIGURATION_ACTIONS.successValidateCode,
    type: AUTH_TYPE.cognito,
    outcome: OUTCOME.success,
  });
};

export const triedToRecoveryPasswordTrack = ({ email, isCognito }) => {
  return sendTrack({
    email,
    page: PAGES.passwordRecovery,
    action: PASSWORD_RECOVERY_ACTIONS.triedToRecovery,
    type: isCognito ? AUTH_TYPE.cognito : AUTH_TYPE.heimdall,
    outcome: OUTCOME.success,
  });
};

export const errorRecoveryPasswordTrack = ({ email, isCognito, properties }) => {
  return sendTrack({
    email,
    properties,
    page: PAGES.passwordRecovery,
    action: PASSWORD_RECOVERY_ACTIONS.errorRecovery,
    type: isCognito ? AUTH_TYPE.cognito : AUTH_TYPE.heimdall,
    outcome: OUTCOME.error,
  });
};

export const successRecoveryPasswordTrack = ({ email, isCognito }) => {
  return sendTrack({
    email,
    page: PAGES.passwordRecovery,
    action: PASSWORD_RECOVERY_ACTIONS.successRecovery,
    type: isCognito ? AUTH_TYPE.cognito : AUTH_TYPE.heimdall,
    outcome: OUTCOME.success,
  });
};

export const triedToResendEmailTrack = ({ email }) => {
  return sendTrack({
    email,
    page: PAGES.passwordRecovery,
    action: PASSWORD_RECOVERY_ACTIONS.triedToResendEmail,
    type: AUTH_TYPE.heimdall,
    outcome: OUTCOME.success,
  });
};

export const errorResendEmailTrack = ({ email, properties }) => {
  return sendTrack({
    email,
    properties,
    page: PAGES.passwordRecovery,
    action: PASSWORD_RECOVERY_ACTIONS.errorResendEmail,
    type: AUTH_TYPE.heimdall,
    outcome: OUTCOME.error,
  });
};

export const successResendEmailTrack = ({ email }) => {
  return sendTrack({
    email,
    page: PAGES.passwordRecovery,
    action: PASSWORD_RECOVERY_ACTIONS.successResendEmail,
    type: AUTH_TYPE.heimdall,
    outcome: OUTCOME.success,
  });
};

export const openChangePasswordPageTrack = ({ properties }) => {
  return sendTrack({
    properties,
    page: PAGES.passwordRecovery,
    action: PASSWORD_RECOVERY_ACTIONS.openChangePasswordPage,
    type: AUTH_TYPE.heimdall,
    outcome: OUTCOME.success,
  });
};

export const triedToChangePasswordTrack = ({ email, isCognito, properties }) => {
  return sendTrack({
    email,
    properties,
    page: PAGES.passwordRecovery,
    action: PASSWORD_RECOVERY_ACTIONS.triedToChangePassword,
    type: isCognito ? AUTH_TYPE.cognito : AUTH_TYPE.heimdall,
    outcome: OUTCOME.success,
  });
};

export const errorChangePasswordTrack = ({ email, isCognito, properties }) => {
  return sendTrack({
    email,
    properties,
    page: PAGES.passwordRecovery,
    action: PASSWORD_RECOVERY_ACTIONS.errorChangePassword,
    type: isCognito ? AUTH_TYPE.cognito : AUTH_TYPE.heimdall,
    outcome: OUTCOME.error,
  });
};

export const successChangePasswordTrack = ({ email, properties, isCognito }) => {
  return sendTrack({
    email,
    properties,
    page: PAGES.passwordRecovery,
    action: PASSWORD_RECOVERY_ACTIONS.successChangePassword,
    type: isCognito ? AUTH_TYPE.cognito : AUTH_TYPE.heimdall,
    outcome: OUTCOME.success,
  });
};
