export const REQUEST_LOGIN_ERROR = {
  GENERIC_FAIL: {
    title: 'Falha ao entrar',
    description: 'Ocorreu uma falha para entrar na plataforma. Aguarde alguns instantes e tente novamente.',
  },
};

export const REQUEST_LOGIN_ERROR_CODE = {
  USER_NOT_CONFIRMED_EXCEPTION: 'UserNotConfirmedException',
};
