import { getBigDataUserID, createBigDataUserID } from '@/services/bigDataUserId';
import { createApi } from '@/services/api';

function trackService(params) {
  if (process.env.NODE_ENV === 'development') {
    return;
  }

  let externalUserId = getBigDataUserID();

  if (!externalUserId) {
    externalUserId = createBigDataUserID();
  }

  createApi({
    timeout: 2000,
  }).post('https://bigdata.contaazul.com/rest/event/track', {
    ...params,
    externalUserId,
    origin: 'APP',
    module: 'AUTHENTICATION',
  });
}

export default trackService;
