<template>
  <div>
    <ds-row>
      <ds-col>
        <ds-illustration name="person-warning" width="120px" height="120px" />
      </ds-col>
    </ds-row>
    <ds-row>
      <ds-col>
        <ds-heading text="Ocorreu um erro inesperado" size="lg" />
      </ds-col>
    </ds-row>
    <ds-row>
      <ds-col>
        <ds-text> Devido a uma instabilidade não foi possível continuar. </ds-text>
        <br />
        <ds-text> Tente novamente em alguns instantes. </ds-text>
      </ds-col>
    </ds-row>
    <ds-row>
      <ds-col size="auto">
        <ds-button theme="primary" full-width @click="tryAgain"> Tentar novamente </ds-button>
      </ds-col>
    </ds-row>
  </div>
</template>

<script>
import { PASSWORD_RECOVERY_COMPONENT, PasswordRecoveryStore } from './store';

export default {
  name: 'ResetTokenValidationError',
  methods: {
    tryAgain() {
      this.setNextComponent(PASSWORD_RECOVERY_COMPONENT.RESET_TOKEN_VALIDATION);
    },
    setNextComponent(component) {
      PasswordRecoveryStore.component = component;
    },
  },
};
</script>
