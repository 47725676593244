import PasswordRecoveryWrapper from '@/components/pageContent/PasswordRecoveryWrapper';
import PasswordRecoveryPage from './PasswordRecoveryPage';

export default [
  {
    path: '/',
    component: PasswordRecoveryPage,
    children: [
      {
        path: '/redefinir-senha/:resetToken?',
        name: 'PasswordRecoveryWrapper',
        component: PasswordRecoveryWrapper,
        meta: {
          origin: 'PASSWORD_RECOVERY',
        },
      },
      {
        path: '/transferir-conta/:resetToken?',
        name: 'PasswordRecoveryWrapper',
        component: PasswordRecoveryWrapper,
        meta: {
          origin: 'ACCOUNT_TRANSFER',
        },
      },
    ],
  },
];
