export function obfuscateString(string, showLength = 2, showStartLength = 0) {
  const stringLength = string.length;
  const parsedShowLength = stringLength > showLength ? showLength : 1;

  return `${string.substr(0, showStartLength)}${'*'.repeat(
    string.length - parsedShowLength - showStartLength,
  )}${string.substr(string.length - parsedShowLength)}`;
}

export default {
  obfuscateString,
};
