<template>
  <div>
    <ds-row>
      <ds-col>
        <component :is="component" @clear-store="clearStoreData"> </component>
      </ds-col>
    </ds-row>
  </div>
</template>

<script>
import ForgotPasswordForm from './ForgotPasswordForm';
import ForgotPasswordSuccess from './ForgotPasswordSuccess';
import ForgotPasswordStore from './ForgotPasswordStore';
import ForgotPasswordCognitoValidation from './ForgotPasswordCognitoValidation';

export default {
  name: 'ForgotPassword',
  components: {
    ForgotPasswordForm,
    ForgotPasswordSuccess,
    ForgotPasswordCognitoValidation,
  },
  computed: {
    component() {
      if (ForgotPasswordStore.cognitoCodeSent) {
        return 'ForgotPasswordCognitoValidation';
      }
      return ForgotPasswordStore.emailAlreadySent ? 'ForgotPasswordSuccess' : 'ForgotPasswordForm';
    },
  },
  mounted() {
    this.clearStoreData();
  },
  methods: {
    clearStoreData() {
      ForgotPasswordStore.email = '';
      ForgotPasswordStore.emailAlreadySent = false;
      ForgotPasswordStore.cognitoCodeSent = false;
      ForgotPasswordStore.cognitoRecoveryCodeData = {};
    },
  },
};
</script>
