<template>
  <ds-form
    :submit-action="submitAction"
    @submit-error="onSubmitError"
    @submit-success="onSubmitSuccess"
    @update:isValid="setFormValid">
    <ds-row content-vertical-align="center">
      <ds-col>
        <ds-heading :text="originConfig.headerText" size="md" />
      </ds-col>
      <ds-col>
        <ds-box-information
          v-if="showPasswordRecoveryError"
          theme="danger"
          title="Sua senha não foi alterada"
          message="Ocorreu uma instabilidade ao salvar. Tente novamente em alguns instantes." />
      </ds-col>
      <ds-col>
        <ds-field label="Nova senha">
          <ds-password-input v-model="password" class="ds-u-color--grey-darkest" required validate />
        </ds-field>
        <ds-field label="Confirme sua senha">
          <ds-password-input v-model="confirmPassword" :custom-validations="customValidations" required />
        </ds-field>
      </ds-col>
    </ds-row>
    <ds-row>
      <ds-col size="6">
        <ds-submit-button :disabled="!isFormValid" full-width>Criar nova senha</ds-submit-button>
      </ds-col>
    </ds-row>
  </ds-form>
</template>

<script>
import { requestPasswordUpdate, accountTransferConfirmation } from '@/resources/passwordUpdate';
import track from '@/services/trackService';
import TRACKS_EVENTS from '@/services/trackConstants';
import {
  openChangePasswordPageTrack,
  triedToChangePasswordTrack,
  successChangePasswordTrack,
  errorChangePasswordTrack,
} from '@/services/authenticationTrack/authenticationTrackService';
import { PasswordRecoveryStore } from './store';

const ORIGIN_TEXTS = {
  ACCOUNT_TRANSFER: {
    headerText: 'Transferência de conta',
    action: accountTransferConfirmation,
  },
  PASSWORD_RECOVERY: {
    headerText: 'Crie uma nova senha',
    action: requestPasswordUpdate,
  },
};

export default {
  name: 'PasswordRecoveryForm',
  data() {
    return {
      password: '',
      confirmPassword: '',
      isFormValid: false,
      customValidations: [
        {
          message: 'As senhas inseridas não coincidem',
          valid: value => this.password === value,
        },
      ],
      showPasswordRecoveryError: false,
    };
  },
  computed: {
    resetToken() {
      return PasswordRecoveryStore.resetToken;
    },
    originConfig() {
      return ORIGIN_TEXTS[PasswordRecoveryStore.origin];
    },
  },
  mounted() {
    track({
      ...TRACKS_EVENTS.loadedCreateNewPasswordForm,
      properties: {
        resetToken: this.resetToken,
      },
    });

    openChangePasswordPageTrack({
      properties: {
        resetToken: this.resetToken,
      },
    });
  },
  methods: {
    submitAction() {
      track({
        ...TRACKS_EVENTS.createNewPasswordFormSubmitted,
        properties: {
          resetToken: this.resetToken,
        },
      });

      triedToChangePasswordTrack({
        isCognito: false,
        properties: {
          resetToken: this.resetToken,
        },
      });
      return this.originConfig.action({
        resetToken: this.resetToken,
        password: this.password,
      });
    },
    onSubmitError() {
      errorChangePasswordTrack({
        isCognito: false,
        properties: {
          resetToken: this.resetToken,
        },
      });
      this.showPasswordRecoveryError = true;
    },
    onSubmitSuccess() {
      successChangePasswordTrack({
        isCognito: false,
        properties: {
          resetToken: this.resetToken,
        },
      });
      this.rediretToLoginWithPasswordRecoverySuccessCause();
    },
    rediretToLoginWithPasswordRecoverySuccessCause() {
      this.$router.push({ name: 'Login', query: { cause: 'password-recovery-success' } });
    },
    setFormValid(value) {
      this.isFormValid = value;
    },
  },
};
</script>
