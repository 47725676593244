const getOriginURL = route => {
  return getURLFromQuery(route) || getURLFromEnv() || getURLDefault();
};

function getURLFromQuery(route) {
  return route.query && route.query.next_origin;
}

function getURLFromEnv() {
  return process.env.VUE_APP_ORIGIN_URL;
}

function getURLDefault() {
  return 'https://app.contaazul.com';
}

export default getOriginURL;
