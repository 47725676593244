import https from 'https';
import api from '@/services/api';

export function bootstrapAuth(tokens) {
  return api.post(`${process.env.VUE_APP_AUTH_INTEGRATION}/internal/v1/boot`, null, {
    headers: {
      'x-auth-bootstrap': JSON.stringify(tokens),
    },
    httpsAgent: new https.Agent({
      rejectUnauthorized: process.env.VUE_APP_ENV === 'production',
    }),
    withCredentials: true,
  });
}
