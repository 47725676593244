<template>
  <ds-loader />
</template>

<script>
import { requestResetTokenValidate, accountTransferTokenValidate } from '@/resources/passwordUpdate';
import { PASSWORD_RECOVERY_COMPONENT, PasswordRecoveryStore } from './store';

const CLIENT_ERROR_ANSWERS_CODE_START = 400;
const CLIENT_ERROR_ANSWERS_CODE_END = 499;

export default {
  name: 'ResetTokenValidation',
  computed: {
    resetToken() {
      return PasswordRecoveryStore.resetToken;
    },
    origin() {
      return PasswordRecoveryStore.origin;
    },
  },
  mounted() {
    this.resetTokenValidation();
  },
  methods: {
    resetTokenValidation() {
      this.validateToken().then(this.resetTokenValidationSuccess).catch(this.resetTokenValidationError);
    },
    validateToken() {
      if (this.origin === 'ACCOUNT_TRANSFER') {
        return accountTransferTokenValidate({
          resetToken: this.resetToken,
        });
      }
      return requestResetTokenValidate({
        resetToken: this.resetToken,
      });
    },
    resetTokenValidationSuccess() {
      this.redirectToPasswordRecovery();
    },
    resetTokenValidationError(error) {
      if (this.isExpiredLinkError(error)) {
        this.redirectToExpiredLink();
      } else {
        this.redirectToError();
      }
    },
    isExpiredLinkError(error) {
      const response = error && error.response;
      const status = response && response.status;

      return status >= CLIENT_ERROR_ANSWERS_CODE_START && status <= CLIENT_ERROR_ANSWERS_CODE_END;
    },
    redirectToPasswordRecovery() {
      this.setNextComponent(PASSWORD_RECOVERY_COMPONENT.PASSWORD_RECOVERY_FORM);
    },
    redirectToExpiredLink() {
      this.setNextComponent(PASSWORD_RECOVERY_COMPONENT.EXPIRED_LINK);
    },
    redirectToError() {
      this.setNextComponent(PASSWORD_RECOVERY_COMPONENT.RESET_TOKEN_VALIDATION_ERROR);
    },
    setNextComponent(component) {
      PasswordRecoveryStore.component = component;
    },
  },
};
</script>
