import api from '@/services/api';

const buildHeaders = () => {
  return {
    headers: {
      origin: window.location.origin,
      domain: 'CONTAAZUL',
    },
  };
};

export const requestPasswordRecovery = ({ email }) => api.post('user/password-recovery', { email }, buildHeaders());
