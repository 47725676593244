import { to } from 'await-to-js';
import { Auth } from 'aws-amplify';
import {
  errorConfirmMfaSigninTrack,
  successConfirmMfaSigninTrack,
} from './authenticationTrack/authenticationTrackService';
import { bootstrapAuthToken } from './bootstrapAuthToken';
import { getError } from './errorsService';

export async function amplifyConfirmSignin(user, code, rememberDevice, app) {
  let err;
  let data;

  const mfaType = user?.challengeName === 'SOFTWARE_TOKEN_MFA' ? 'SOFTWARE_TOKEN_MFA' : 'SMS_MFA';

  [err, data] = await to(Auth.confirmSignIn(user, code, mfaType));

  if (err) {
    errorConfirmMfaSigninTrack({
      email: user.username,
      properties: {
        step: 'cognitoConfirmSignIn',
        error: err,
      },
    });
    return Promise.reject(getError(err.code));
  }

  if (rememberDevice) {
    await Auth.rememberDevice();
  }

  [err, data] = await to(bootstrapAuthToken(data, app));

  if (err) {
    errorConfirmMfaSigninTrack({
      email: user.username,
      properties: {
        step: err.step,
        error: err.message,
      },
    });
    return Promise.reject(getError(err?.message?.code));
  }

  successConfirmMfaSigninTrack({
    email: user.username,
    properties: {
      step: 'redirectToApp',
      jwt: data?.jwt,
    },
  });

  return Promise.resolve(data);
}
