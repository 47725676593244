import { PRODUCT } from './locationConstants';

export function getProductLocationByUser({ app = '', userId = '' } = {}) {
  if (app) {
    return getSandboxLocation(app);
  }

  if (userId) {
    return PRODUCT.CAPRO.location;
  }

  return PRODUCT.DEFAULT.location;
}

function getSandboxLocation(app) {
  return `http://${app}.dev.contaazul.local`;
}
