import LoginPageRoutes from '@/views/loginPage/routes';
import loginSandbox from '@/views/loginSandbox/routes';
import PasswordRecoveryPageRoutes from '@/views/passwordRecoveryPage/routes';

export default [
  ...LoginPageRoutes,
  ...PasswordRecoveryPageRoutes,
  ...loginSandbox,
  {
    path: '*',
    redirect: '/',
  },
];
