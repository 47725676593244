import { to } from 'await-to-js';
import {
  errorValidateTotpCodeTrack,
  successValidateTotpCodeTrack,
} from './authenticationTrack/authenticationTrackService';
import { bootstrapAuthToken } from './bootstrapAuthToken';
import { getError } from './errorsService';
import { verifyTotpToken, setPreferredMFA } from './amplifyService';

export async function confirmTotpToken(code, user) {
  const [totpErr] = await to(verifyTotpToken(code));

  if (totpErr) {
    errorValidateTotpCodeTrack({
      email: user.username,
      properties: {
        step: 'verifyTotpToken',
        error: totpErr,
      },
    });
    return Promise.reject(getError(totpErr.code));
  }

  await to(setPreferredMFA(user, 'TOTP'));
  const [err, data] = await to(bootstrapAuthToken(user));

  if (err) {
    errorValidateTotpCodeTrack({
      email: user.username,
      properties: {
        step: err.step,
        error: err.message,
      },
    });
    return Promise.reject(getError(err?.message?.code));
  }

  successValidateTotpCodeTrack({
    email: user.username,
    properties: {
      step: 'redirectToApp',
      jwt: data?.jwt,
    },
  });

  return Promise.resolve(data);
}
