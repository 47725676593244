<template>
  <div class="recover-password">
    <component :is="component"> </component>
  </div>
</template>

<script>
import { PASSWORD_RECOVERY_COMPONENT, PasswordRecoveryStore } from './store';
import ResetTokenValidation from './ResetTokenValidation';
import ResetTokenValidationError from './ResetTokenValidationError';
import PasswordRecoveryForm from './PasswordRecoveryForm';
import ExpiredLink from './ExpiredLink';

export default {
  name: 'PasswordRecoveryWrapper',
  components: {
    ResetTokenValidation,
    ResetTokenValidationError,
    PasswordRecoveryForm,
    ExpiredLink,
  },
  computed: {
    component() {
      return PasswordRecoveryStore.component;
    },
    resetToken() {
      return PasswordRecoveryStore.resetToken;
    },
  },
  mounted() {
    this.setResetToken('');
    this.initialize();
  },
  methods: {
    initialize() {
      const resetTokenParam = this.getResetTokenParam();
      this.setOrigin(this.$route.meta);

      if (resetTokenParam) {
        this.setResetToken(resetTokenParam);
        this.clearUrlsParams();
      }

      if (this.hasResetToken()) {
        this.setNextComponent(PASSWORD_RECOVERY_COMPONENT.RESET_TOKEN_VALIDATION);
      } else {
        this.rediretToForgotPassword();
      }
    },
    getResetTokenParam() {
      const { resetToken } = this.$route.params;
      return resetToken;
    },
    setResetToken(resetToken) {
      PasswordRecoveryStore.resetToken = resetToken;
    },
    setOrigin({ origin }) {
      PasswordRecoveryStore.origin = origin;
    },
    clearUrlsParams() {
      this.$router.replace({
        name: 'PasswordRecoveryWrapper',
        params: {},
      });
    },
    hasResetToken() {
      return !!this.resetToken;
    },
    setNextComponent(component) {
      PasswordRecoveryStore.component = component;
    },
    rediretToForgotPassword() {
      this.$router.push({ name: 'ForgotPassword' });
    },
  },
};
</script>
