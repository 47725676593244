import Cookies from 'js-cookie';
import { v4 as uuidV4 } from 'uuid';

const cookieName = 'ca.bigdata.user.id';

export const getBigDataUserID = () => {
  return Cookies.get(cookieName);
};

export const createBigDataUserID = () => {
  const bigDataUserID = uuidV4();
  setBigDataUserID(bigDataUserID, buildOptions());
  return bigDataUserID;
};

function setBigDataUserID(value, options) {
  return Cookies.set(cookieName, value, options);
}

function buildOptions() {
  const domain = process.env.VUE_APP_COOKIE_DOMAIN || '.contaazul.com';

  return {
    expires: 30,
    path: '/',
    domain,
  };
}
