import { fetchLoginBannerImage } from '@/resources/wordpressResource';

export async function getLoginBannerImageUrl() {
  try {
    const [post] = await fetchLoginBannerImage();
    const imageLogin = post?.acf.image_login;

    return imageLogin;
  } catch {
    return null;
  }
}
