import Vue from 'vue';

export const PASSWORD_RECOVERY_COMPONENT = {
  RESET_TOKEN_VALIDATION: 'ResetTokenValidation',
  RESET_TOKEN_VALIDATION_ERROR: 'ResetTokenValidationError',
  PASSWORD_RECOVERY_FORM: 'PasswordRecoveryForm',
  EXPIRED_LINK: 'ExpiredLink',
};

export const PasswordRecoveryStore = Vue.observable({
  resetToken: '',
  component: '',
  origin: '',
});
