<template>
  <div class="blank-side" :style="cssBlankSide"></div>
</template>

<script>
import * as wordpressService from '@/services/wordpressService';

export default {
  name: 'BlankSide',
  data() {
    return {
      cssBlankSide: {
        backgroundImage: '',
      },
    };
  },
  async created() {
    await this.setBanner();
  },
  methods: {
    async setBanner() {
      const url = await wordpressService.getLoginBannerImageUrl();
      this.cssBlankSide.backgroundImage = `url(${url})`;
    },
  },
};
</script>

<style scoped>
.blank-side {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
}

.blank-side__image {
  width: 100%;
}
</style>
