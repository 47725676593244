export default function getRequestErrorMessage(error, defaultError) {
  if (error?.cognito) {
    return {
      title: error.title || defaultError.title,
      description: error.detail || defaultError.description,
    };
  }

  return error?.response?.data?.error || defaultError;
}
