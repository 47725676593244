export const ERROR_LOGIN = {
  SMS_MFA: {
    title: 'MFA - Código de acesso obrigatório',
    detail: 'Um código de confirmação foi enviado via SMS',
    code: 'SMS_MFA',
  },
  CannotGetSession: {
    title: 'Não foi possível obter sessão.',
    detail: 'Ocorreu uma falha ao entrar na plataforma. Aguarde alguns instantes e tente novamente.',
    code: 'CannotGetSession',
  },
  MissingTokenData: {
    title: 'Não foi possível obter um token de autorização.',
    detail: 'Ocorreu uma falha ao entrar na plataforma. Aguarde alguns instantes e tente novamente.',
    code: 'MissingTokenData',
  },
  MissingTokenDataAfterLogin: {
    title: 'Não foi possível obter dados de autorização.',
    detail: 'Ocorreu uma falha ao entrar na plataforma. Aguarde alguns instantes e tente novamente.',
    code: 'MissingTokenDataAfterLogin',
  },
  NotAuthorizedException: {
    title: 'Credenciais incorretas.',
    detail: 'O e-mail ou a senha informados estão incorretos. Verifique os dados digitados e tente entrar novamente.',
    code: 'NotAuthorizedException',
  },
  UserLambdaValidationException: {
    title: 'Falha ao autenticar.',
    detail: 'Ocorreu uma falha ao autenticar. Aguarde alguns instantes e tente novamente.',
    code: 'UserLambdaValidationException',
  },
  UserNotFoundException: {
    title: 'E-mail não encontrado.',
    detail: 'O e-mail informado não está cadastrado. Verifique os dados digitados e tente novamente.',
    code: 'UserNotFoundException',
  },
  UserRegistredException: {
    title: 'Não foi possível criar sessão.',
    detail: 'Ocorreu uma falha ao criar uma sessão na plataforma. Aguarde alguns instantes e tente novamente.',
    code: 'UserRegistredException',
  },
  UserAlreadyRegisteredException: {
    title: 'E-mail já cadastrado.',
    detail: 'Ocorreu uma falha ao criar uma sessão na plataforma. O e-mail já está cadastrado.',
    code: 'UserAlreadyRegisteredException',
  },
  EmailValidationException: {
    title: 'Não foi possível criar sessão.',
    detail:
      'Ocorreu uma falha ao verificar se o e-mail está disponível para uso. Aguarde alguns instantes e tente novamente.',
    code: 'EmailValidationException',
  },
  InvalidPasswordException: {
    title: 'Não foi possível criar sessão.',
    detail: 'Ocorreu uma falha ao criar um sessão na plataforma. A senha digitada é fraca.',
    code: 'InvalidPasswordException',
  },
  LimitExceededException: {
    title: 'Não foi possível reenviar o código de verificação.',
    detail: 'O limite de tentativas foi excedido. Aguarde alguns instantes e tente novamente.',
    code: 'LimitExceededException',
  },
  ExpiredCodeException: {
    title: 'Não foi possível criar sessão.',
    detail: 'O código de verificação não é mais válido. Solicite um novo código.',
    code: 'ExpiredCodeException',
  },
  CodeMismatchException: {
    title: 'Não foi possível criar sessão.',
    detail: 'O código de verificação está incorreto. Tente novamente ou solicite um novo código.',
    code: 'CodeMismatchException',
  },
  UserNotConfirmedException: {
    title: 'Não foi possível obter sessão.',
    detail: 'Usuário não confirmado. Confirme seu usuário com o código recebido via SMS.',
    code: 'UserNotConfirmedException',
  },
  FlowNotAuthorizedException: {
    title: 'Não foi possível fazer alteração.',
    detail: 'Este fluxo não é permitido.',
    code: 'FlowNotAuthorizedException',
  },
  EnableSoftwareTokenMFAException: {
    title: 'O código está incorreto.',
    detail: 'Insira o código gerado pelo aplicativo de autenticação.',
    code: 'EnableSoftwareTokenMFAException',
  },
};

export function getError(code, urid) {
  const error = ERROR_LOGIN[code]
    ? {
        cognito: true,
        ...ERROR_LOGIN[code],
        urid,
      }
    : {
        cognito: true,
        ...ERROR_LOGIN.CannotGetSession,
        urid,
      };
  return error;
}
