<template>
  <ds-form
    :on-submit="onSubmit"
    :is-valid.sync="isFormValid"
    @submit-success="onSubmitSuccess"
    @submit-error="onSubmitError">
    <ds-row content-vertical-align="center">
      <ds-col>
        <ds-heading size="md" text="Recuperar senha" />
        <ds-p>Enviamos um código de verificação de 6 dígitos para {{ deliveryType }}</ds-p>
        <ds-p>
          <b>{{ destination }}</b>
          .
        </ds-p>
        <ds-p class="ds-u-margin-top--md"
          >Insira o código abaixo para continuar o processo de redefinição de senha:
        </ds-p>
        <ds-field label="Código" class="ds-u-margin-top--md">
          <ds-input
            v-model="verificationCode"
            :custom-validations="verificationCodeValidation"
            maxlength="6"
            required
            @change="validateCodeLength" />
        </ds-field>
      </ds-col>
    </ds-row>
    <div v-if="codeTyped">
      <ds-row content-vertical-align="center">
        <ds-col>
          <ds-p>Insira e confirme sua nova senha:</ds-p>
          <ds-field label="Nova senha" class="ds-u-margin-top--md">
            <ds-password-input v-model="password" class="ds-u-color--grey-darkest" required validate />
          </ds-field>
          <ds-field label="Confirme sua senha">
            <ds-password-input v-model="confirmPassword" :custom-validations="passwordValidation" required />
          </ds-field>
        </ds-col>
      </ds-row>
      <ds-row>
        <ds-col size="6">
          <ds-submit-button :disabled="!isFormValid" full-width>Criar nova senha</ds-submit-button>
        </ds-col>
      </ds-row>
    </div>
  </ds-form>
</template>

<script>
import { forgotPasswordChange } from '@/services/amplifyService';
import { toasterService } from '@contaazul/design-system';
import {
  triedToChangePasswordTrack,
  successChangePasswordTrack,
  errorChangePasswordTrack,
} from '@/services/authenticationTrack/authenticationTrackService';
import ForgotPasswordStore from './ForgotPasswordStore';

export default {
  name: 'ForgotPasswordCognitoValidation',
  data() {
    return {
      password: '',
      confirmPassword: '',
      verificationCode: '',
      cognitoCodeData: ForgotPasswordStore.cognitoRecoveryCodeData.CodeDeliveryDetails,
      isFormValid: false,
      codeTyped: false,
      isInvalidCode: false,
      verificationCodeValidation: [
        {
          valid: () => this.verificationCode.length === 6,
          message: 'O código precisa conter 6 digitos',
        },
      ],
      passwordValidation: [
        {
          valid: () => this.password === this.confirmPassword,
          message: 'As senhas não conferem',
        },
      ],
    };
  },
  computed: {
    isEmailDeliveryMedium() {
      return this.cognitoCodeData.DeliveryMedium === 'EMAIL';
    },
    destination() {
      return this.isEmailDeliveryMedium ? ForgotPasswordStore.email : this.cognitoCodeData.Destination;
    },
    deliveryType() {
      return this.isEmailDeliveryMedium ? `o e-mail ` : `seu celular de final `;
    },
  },
  methods: {
    validateCodeLength(value) {
      if (value.length === 6) {
        this.codeTyped = true;
      }
    },
    onSubmit() {
      triedToChangePasswordTrack({
        email: ForgotPasswordStore.email,
        isCognito: true,
      });
      return forgotPasswordChange(ForgotPasswordStore.email, this.verificationCode, this.password);
    },
    onSubmitSuccess() {
      successChangePasswordTrack({
        email: ForgotPasswordStore.email,
        isCognito: true,
      });

      toasterService.success({
        title: 'Senha alterada com sucesso',
        content: 'Faça o login com sua nova senha',
      });
      this.$router.push({ name: 'Login' });
    },
    onSubmitError(err) {
      errorChangePasswordTrack({
        email: ForgotPasswordStore.email,
        isCognito: true,
        properties: {
          error: err,
        },
      });
      const invalidCodeCodes = ['CodeMismatchException', 'ExpiredCodeException'];
      const message = {
        title: 'Ocorreu um erro ao alterar a senha',
        content: 'Por favor, inicie o processo de recuperação de senha novamente.',
      };

      if (invalidCodeCodes.includes(err?.code)) {
        message.title = 'O código inserido está incorreto.';
      }

      toasterService.error(message);
      this.$emit('clear-store');
    },
  },
};
</script>
