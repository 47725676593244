<template>
  <div>
    <ds-row>
      <ds-col>
        <ds-illustration name="person-warning" width="120px" height="120px" />
      </ds-col>
    </ds-row>
    <ds-row>
      <ds-col>
        <ds-heading :text="originConfig.headerMessage" size="lg" />
      </ds-col>
    </ds-row>
    <ds-row>
      <ds-col>
        <ds-text> {{ originConfig.infoMessage }} </ds-text>
      </ds-col>
    </ds-row>
    <ds-row v-if="originConfig.showForgotPassword">
      <ds-col size="auto">
        <ds-button theme="primary" full-width @click="redirectToForgotPassword">
          Solicitar novo link de recuperação
        </ds-button>
      </ds-col>
    </ds-row>
  </div>
</template>

<script>
import { PasswordRecoveryStore } from './store';

const ORIGIN_TEXTS = {
  ACCOUNT_TRANSFER: {
    headerMessage: 'O link para migração de conta expirou ou já foi utilizado',
    infoMessage: 'O link expirou pois passaram mais de 7 dias desde a solicitação ou já foi utilizado.',
    showForgotPassword: false,
  },
  PASSWORD_RECOVERY: {
    headerMessage: 'O link para recuperar a sua senha expirou ou já foi utilizado',
    infoMessage:
      'Passaram mais de 24h desde a solicitação do link de recuperação da senha ou já foi utilizado. Solicite um novo link para recuperar sua senha.',
    showForgotPassword: true,
  },
};

export default {
  name: 'ExpiredLink',
  computed: {
    originConfig() {
      return ORIGIN_TEXTS[PasswordRecoveryStore.origin];
    },
  },
  methods: {
    redirectToForgotPassword() {
      this.$router.push({ name: 'ForgotPassword' });
    },
  },
};
</script>
