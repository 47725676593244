<template>
  <div>
    <ds-row>
      <ds-col>
        <ds-heading text="Verificação em Duas Etapas" />
      </ds-col>
    </ds-row>
    <ds-row>
      <ds-col>
        <ds-p>
          Olá, <b>{{ obfuscatedEmail }}</b
          >!
        </ds-p>
        <ds-p>
          Uma mensagem de texto com um código de verificação de 6 dígitos acabou de ser enviada {{ smsTextComplete }}
        </ds-p>
      </ds-col>
    </ds-row>
    <ds-row v-if="phone" content-vertical-align="center" vertical-offset="2">
      <ds-col :size="2">
        <ds-avatar size="xs" alt="Avatar do Usuário" />
      </ds-col>
      <ds-col :size="8">
        <div>
          <ds-text>
            <!-- TODO: change phone value to obfuscate value when component supports  -->
            <b><ds-phone-format :value="phone" /></b>
          </ds-text>
        </div>
        <div>
          <ds-link @click="changePhone"> Alterar </ds-link>
        </div>
      </ds-col>
    </ds-row>
    <ds-row vertical-offset="2">
      <ds-col>
        <ds-form
          :submit-action="confirmSignup"
          @submit-error="onConfirmSignupError"
          @submit-success="onConfirmSignupSuccess">
          <ds-field label="Digite o código">
            <ds-input v-model="verificationCode" :custom-validations="customValidations" :maxlength="6" required />
          </ds-field>
          <ds-field>
            <ds-checkbox v-model="shouldNotAskMfaAgain" label="Não pergunte novamente neste dispositivo" />
          </ds-field>
          <ds-submit-button> Autenticar </ds-submit-button>
          <ds-request-button
            theme="link"
            :on-request="resendCode"
            :on-request-success="onResendCodeSuccess"
            :on-request-error="onResendCodeError">
            Reenviar Código
          </ds-request-button>
        </ds-form>
      </ds-col>
    </ds-row>
  </div>
</template>

<script>
import { obfuscateString } from '@/services/obfuscateStringService';
import { configure } from '@auth-integration-lib/authentication-lib';
import { toasterService } from '@contaazul/design-system';
import { Buffer } from 'buffer';

export default {
  name: 'MfaSignupVerification',
  data() {
    return {
      phone: '',
      email: '',
      app: '',
      redirect: '',
      verificationCode: null,
      customValidations: [
        {
          message: 'O código precisa conter 6 digitos',
          valid(value) {
            return value.length === 6;
          },
        },
      ],
      shouldNotAskMfaAgain: true,
    };
  },
  computed: {
    obfuscatedEmail() {
      return this.email ? obfuscateString(this.email, 3, 5) : '';
    },
    smsTextComplete() {
      return this.phone ? 'para seu número de telefone:' : 'para o número de telefone cadastrado na sua conta.';
    },
  },
  mounted() {
    const { email = '', phone = '', app = '', resend = false, redirect = '' } = this.$route.query;

    if (!phone) {
      this.email = Buffer.from(email, `base64`).toString(`ascii`);

      this.resendCode().catch(err => this.onResendCodeError(err));
      return;
    }

    this.email = Buffer.from(email, `base64`).toString(`ascii`);
    this.phone = Buffer.from(phone, `base64`).toString(`ascii`);
    this.app = app;
    this.redirect = redirect;

    if (resend) {
      this.resendCode().catch(err => this.onResendCodeError(err));
    }
  },
  methods: {
    confirmSignup() {
      return configure(process.env.VUE_APP_ENV).confirmSignUp({
        app: this.app,
        email: this.email,
        code: this.verificationCode,
        product: 'CAPRO',
      });
    },
    onConfirmSignupSuccess() {
      toasterService.success({
        title: 'Usuário confirmado com sucesso!',
        content: 'Realize login novamente',
      });
    },
    onConfirmSignupError({ title, detail }) {
      toasterService.error({
        title,
        content: detail,
      });
    },
    resendCode() {
      return configure(process.env.VUE_APP_ENV).resendConfirmCode({
        email: this.email,
      });
    },
    onResendCodeSuccess({ data }) {
      toasterService.success({
        title: 'Sucesso!',
        content: data.message,
      });
    },
    onResendCodeError({ title, detail }) {
      toasterService.error({
        title,
        content: detail,
      });
    },
    changePhone() {
      const params = {
        phone: Buffer.from(this.phone).toString(`base64`),
        email: Buffer.from(this.email).toString(`base64`),
      };

      if (this.redirect) {
        params.redirect = this.redirect;
      }

      this.$router.push({
        name: 'MfAChangePhonePage',
        params,
      });
    },
  },
};
</script>
