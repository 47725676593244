import Vue from 'vue';

const ForgotPasswordStore = Vue.observable({
  email: '',
  emailAlreadySent: false,
  cognitoCodeSent: false,
  cognitoRecoveryCodeData: {},
});

export default ForgotPasswordStore;
