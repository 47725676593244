<template>
  <div class="login-page">
    <div class="login-page__row">
      <div class="login-page__column login-page__column__content-side">
        <div class="login-page__content-side">
          <content-side />
        </div>
      </div>
      <div class="login-page__column login-page__column__blank-side">
        <div class="login-page__blank-side">
          <blank-side />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import track from '@/services/trackService';
import TRACKS_EVENTS from '@/services/trackConstants';
import ContentSide from '@/components/contentSide/ContentSide';
import BlankSide from '@/components/blankSide/BlankSide';

export default {
  name: 'MainPage',
  components: {
    ContentSide,
    BlankSide,
  },
  mounted() {
    track(TRACKS_EVENTS.pageLoadedSuccessfully);
  },
};
</script>

<style>
.login-page .ds-row + .ds-row {
  margin-top: 0;
}

.login-page__row {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: start;
  align-items: flex-start;
  margin: 0;
  height: 100vh;
}

.login-page__column {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  padding: 0;
  position: relative;
}

.login-page__column__content-side {
  background-color: #ffffff;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  min-height: 100vh;
}

.login-page__content-side {
  margin: auto;
  max-width: 450px;
  padding: 5em 4em 1em 4em;
}

.login-page__column__blank-side {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.login-page__blank-side {
  width: 100%;
  height: 100%;
  position: fixed;
}

@media screen and (max-width: 1199px) {
  .login-page__column__content-side {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .login-page__content-side {
    padding: 4em 2em 1em 2em;
  }

  .login-page__column__blank-side {
    display: none;
  }
}
</style>
