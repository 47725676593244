export default [
  {
    id: 'expired-token',
    content: {
      theme: 'danger',
      title: 'O acesso a esta conta foi desconectado',
      message: 'Por motivos de segurança, sua sessão foi expirada. Por favor, insira seus dados para voltar a entrar.',
    },
    rules: {
      shouldShowByURLParameter: true,
    },
  },
  {
    id: 'credential-changed',
    content: {
      theme: 'danger',
      title: 'O acesso a esta conta foi desconectado',
      message:
        'Identificamos que a senha deste usuário foi alterada recentemente pelo Administrador. Por favor, insira os novos dados para voltar a entrar.',
    },
    rules: {
      shouldShowByURLParameter: true,
    },
  },
  {
    id: 'user-removed',
    content: {
      theme: 'danger',
      title: 'Autorização necessária para voltar a acessar esta conta',
      message:
        'Este usuário não está mais associado à esta empresa. Solicite acesso ao Administrador ou realize o login com outro usuário.',
    },
    rules: {
      shouldShowByURLParameter: true,
    },
  },
  {
    id: 'password-recovery-success',
    content: {
      theme: 'success',
      title: 'Senha criada com sucesso',
      message: 'Acesse sua conta com a nova senha.',
    },
    rules: {
      shouldShowByURLParameter: true,
    },
  },
];
