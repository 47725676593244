const screens = {
  loginPageHome: 'LOGIN_HOME_PAGE',
  passwordRecoveryFormPage: 'PASSWORD_RECOVERY_FORM_PAGE',
  createNewPasswordPage: 'CREATE_NEW_PASSWORD_PAGE',
};

const TRACKS_EVENTS = {
  pageLoadedSuccessfully: {
    screen: screens.loginPageHome,
    event: 'LoginPage - pageLoadedSuccessfully',
  },
  closeAlertMessageWelcomeNewLogin: {
    screen: screens.loginPageHome,
    event: 'LoginPage - closeAlertMessageWelcomeNewLogin',
  },
  clickedLinkToCreateNewAccount: {
    screen: screens.loginPageHome,
    event: 'LoginPage - clickedLinkToCreateNewAccount',
  },
  clickedLinkToGoToContaAzulMais: {
    screen: screens.loginPageHome,
    event: 'LoginPage - clickedLinkToGoToContaAzulMais',
  },
  userLoggedSuccessfully: {
    screen: screens.loginPageHome,
    event: 'LoginJourney - userLoggedSuccessfully',
  },
  loginFormSubmitted: {
    screen: screens.loginPageHome,
    event: 'LoginJourney - loginFormSubmitted',
  },
  clickedLinkToRecoverPassword: {
    screen: screens.loginPageHome,
    event: 'PasswordRecoveryJourney - clickedLinkToRecoverPassword',
  },
  clickedCancelButton: {
    screen: screens.passwordRecoveryFormPage,
    event: 'PasswordRecoveryJourney - clickedCancelButton',
  },
  passwordRecoveryFormSubmitted: {
    screen: screens.passwordRecoveryFormPage,
    event: 'PasswordRecoveryJourney - passwordRecoveryFormSubmitted',
  },
  clickedLinkSupportEmail: {
    screen: screens.passwordRecoveryFormPage,
    event: 'PasswordRecoveryJourney - clickedLinkSupportEmail',
  },
  clickedLinkToResendEmailWithResetToken: {
    screen: screens.passwordRecoveryFormPage,
    event: 'PasswordRecoveryJourney - clickedLinkToResendEmailWithResetToken',
  },
  clickedLinkContactOurSupport: {
    screen: screens.passwordRecoveryFormPage,
    event: 'PasswordRecoveryJourney - clickedLinkContactOurSupport',
  },
  clickedButtonGoBackLoginPage: {
    screen: screens.passwordRecoveryFormPage,
    event: 'PasswordRecoveryJourney - clickedButtonGoBackLoginPage',
  },
  loadedCreateNewPasswordForm: {
    screen: screens.createNewPasswordPage,
    event: 'PasswordRecoveryJourney - loadedCreateNewPasswordForm',
  },
  createNewPasswordFormSubmitted: {
    screen: screens.createNewPasswordPage,
    event: 'PasswordRecoveryJourney - createNewPasswordFormSubmitted',
  },
  buttonAccessMyAccountClicked: {
    screen: screens.createNewPasswordPage,
    event: 'PasswordRecoveryJourney - buttonAccessMyAccountClicked',
  },
};

export default TRACKS_EVENTS;
