import api from '@/services/api';

export function migrateSignin(email, password) {
  return api
    .post(`${process.env.VUE_APP_SERVICES_PATH}/auth-lambdas/migrate-signin`, {
      email,
      password,
    })
    .then(response => ({ data: response?.data }));
}
