import Vue from 'vue';
import * as urlQueryParamsHandler from '@/services/urlQueryParamsHandler';
import App from './App.vue';
import router from './router';

import './design-system';

Vue.config.productionTip = false;

new Vue({
  router,
  beforeCreate() {
    urlQueryParamsHandler.saveInStore(this.$route);
  },
  render: h => h(App),
}).$mount('#app');
